// external imports
import React from "react";
import { NavLink } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";

// internal imports
import { Background, FourGround } from "../../../assets/js/theme";
import { LoginField } from "./LoginField";
import { asyncLogin } from "../../../features/actions/authAsyncAction";
import "./login.css";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../../components/admin/header/Header";

export default function Login() {
  const { t } = useTranslation();
  const [passInputType, setPassInputType] = useState("password");

  const dispatch = useDispatch();
  const loginValidate = Yup.object({
    mobile: Yup.string()
      .min(11, "Write 11 digit mobile number")
      .max(11, "Over than 11 digit mobile number")
      .required("Field Cannot be empty"),
    password: Yup.string().required("Field Cannot be empty"),
  });

  const handleLogin = (loginData) => {
    const loader = document.querySelector(".Loader");
    loader.style.display = "block";

    // send to login control
    asyncLogin(dispatch, loginData);
  };

  //Fleck:
  (async function () {
    if (CSS["paintWorklet"] === undefined) {
      await import("https://unpkg.com/css-paint-polyfill");
    }

    // The code for this worklet can be found here: https://github.com/georgedoescode/houdini-fleck-patterns/blob/main/fleck-worklet.js
    CSS.paintWorklet.addModule(
      "https://unpkg.com/@georgedoescode/houdini-fleck"
    );

    // Fix a weird Safari/Firefox polyfill issue...
    setTimeout(() => {
      document.querySelectorAll(".fleck-demo").forEach((el) => {
        el.style.width = "100%";
      });
    }, 250);
  })();

  return (
    <Background>
      <FourGround
        className="client_section fleck-demo center-xy"
        data-theme="login"
      >
        <div className="Loader"></div>
        <ToastContainer position="top-right" theme="colored" />

        <div className="text-center loginWeapper">
          <main className="form-signin">
            <Formik
              initialValues={{
                mobile: "",
                password: "",
              }}
              validationSchema={loginValidate}
              onSubmit={(loginData) => {
                handleLogin(loginData);
              }}
            >
              {() => (
                <div>
                  <Form>
                    <div className="mb-4">
                      <img
                        className="newLogoo"
                        src="/assets/img/logo.png"
                        alt=""
                      />
                    </div>
                    <LoginField
                      label="Mobile"
                      name="mobile"
                      type="text"
                      placeholder="Enter your mobile number"
                    />
                    <LoginField
                      label="Password"
                      name="password"
                      type={passInputType}
                      id="password"
                      placeholder="Provide your password"
                    />
                    <div className="showPassword">
                      <input
                        style={{ cursor: "pointer" }}
                        className="form-check-input shadow-none"
                        type="checkbox"
                        name="showPass"
                        id="showPass"
                        onChange={(e) =>
                          setPassInputType(
                            e.target.checked ? "text" : "password"
                          )
                        }
                      />
                      <label
                        style={{ cursor: "pointer" }}
                        className="form-check-label text-black fw-bold"
                        htmlFor="showPass"
                      >
                        &nbsp; Show Password
                      </label>
                    </div>
                    <div className="mt-4 button-container">
                      <button
                        className="btn btn-success cstm-login-btn"
                        type="submit"
                      >
                        Login
                      </button>
                      <NavLink to="/">
                        <button
                          className="btn  btn-danger cstm-login-cancel"
                          type="button"
                        >
                          Cancel
                        </button>
                      </NavLink>
                    </div>
                    <br />
                    <p className="mt-4 mb-1 text-muted">
                      &copy; {t("shunnoIT")} - {new Date().getFullYear()}
                    </p>
                  </Form>
                </div>
              )}
            </Formik>
          </main>
        </div>
      </FourGround>
    </Background>
  );
}
