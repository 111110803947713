import PaymentModal from "./paymentModal";
import bkashImg from "../assets/img/bkash.jpg";
import { useSelector } from "react-redux";
import QrCode from "./QrCode";
import moment from "moment";

const Payment = () => {
  const ispOwnerInfo = useSelector(
    (state) => state.persistedReducer.auth?.currentUser?.customer?.ispOwner
  );

  const userData = useSelector(
    (state) => state.persistedReducer.auth?.currentUser.customer
  );

  // get payment get way status
  const hasPG = userData.ispOwner.bpSettings.hasPG;

  const diffFormBillDate = moment(userData.billingCycle).diff(
    new Date(),
    "days"
  );

  return (
    <>
      <div className="payment-container">
        {diffFormBillDate <= 7 && userData.paymentStatus !== "paid" && (
          <div className="mt-4 text-center">
            <div className="h5 p-1">
              <strong>Warning: </strong> Your billing date expire in{" "}
              <span className="text-warning">
                {moment(userData.billingCycle).format("DD MMM YYYY")}
              </span>
            </div>
            {hasPG && (
              <button
                data-bs-toggle="modal"
                data-bs-target="#billPaymentModal"
                type="button"
                className="btn btn-success "
              >
                Pay now
              </button>
            )}
          </div>
        )}
        <div className="d-flex flex-wrap justify-content-center container paymentMethod-container">
          <div
            className="paymentMethod"
            data-bs-toggle="modal"
            data-bs-target="#billPaymentModal"
          >
            <h3 style={{ color: "#3eff00" }} className="text-center">
              Payment with
            </h3>
            <img
              style={{
                width: "18rem",
                textAlign: "center",
                marginBottom: "1rem",
              }}
              src={bkashImg}
              alt=""
            />
            <h2>Payment</h2>
          </div>
          <div>
            <h3 style={{ color: "#3eff00" }} className="text-center">
              Scan QR Code
            </h3>
            <QrCode
              size={174}
              ispInfo={{
                company: ispOwnerInfo?.company,
                mobile: ispOwnerInfo?.mobile,
                netFeeId: ispOwnerInfo?.netFeeId,
                address: ispOwnerInfo?.address,
              }}
            />
          </div>
        </div>
      </div>
      <PaymentModal />
    </>
  );
};

export default Payment;
