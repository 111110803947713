import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  customerPackage,
  getPackagesByIspOwer,
} from "../features/getIspOwnerUsersApi";
import PackageChangeModal from "./packageChangeModal";
import moment from "moment";

//package change functional korte hobe
export default function Packages() {
  const dispatch = useDispatch();

  // get own data
  const userData = useSelector(
    (state) => state.persistedReducer.auth?.currentUser.customer
  );

  // ispOwner permission
  const permission = userData?.ispOwner.bpSettings;

  // get all packages
  const packages = useSelector((state) => state.package.packages);
  const hotspotPackage = useSelector(
    (state) => userData.userType === "hotspot" && state?.package?.hotspotPackage
  );

  // find alias name method
  const findAliasName = (ownPackage) => {
    const findItem = packages.find((item) => item.id.includes(ownPackage));
    return findItem;
  };

  const packageChange = (value) => {
    const temp = packages.find((item) => item.id === value);
    return temp;
  };

  // customer package
  const [changePackage, setChangePackage] = useState("");

  // get packages api call
  useEffect(() => {
    getPackagesByIspOwer(dispatch);
    customerPackage(userData.id, setChangePackage);
  }, []);

  return (
    <div className="client_page_packages mx-auto" style={{ minHeight: "54vh" }}>
      {permission?.showCustomerPanelPackage ? (
        <>
          <div
            style={{
              backdropFilter: "blur(10px)",
              backgroundColor: "rgba(6, 171, 20, 0.205)",
              boxShadow: "0px 0px 10px 0px rgba(255, 255, 255, 0.405)",
              padding: "30px",
            }}
          >
            <div className="current_package_container">
              <h5>Current package:</h5>
              <div className="packages_info_wraper">
                <p>
                  Package:
                  {!userData?.reseller && (
                    <span
                      className="badge bg-secondary"
                      style={{ marginLeft: "5px" }}
                    >
                      {userData.userType === "hotspot"
                        ? userData?.hotspot.profile
                        : findAliasName(userData?.mikrotikPackage)?.aliasName ||
                          findAliasName(userData?.mikrotikPackage)?.name ||
                          findAliasName(userData?.mikrotikPackage)?.name}
                    </span>
                  )}
                  {userData?.reseller && (
                    <span
                      className="badge bg-secondary"
                      style={{ marginLeft: "5px" }}
                    >
                      {findAliasName(userData?.pppoe.profile)?.name}
                    </span>
                  )}
                </p>

                <p>
                  Package rate:
                  <span
                    className="badge bg-warning text-dark"
                    style={{ marginLeft: "5px" }}
                  >
                    {userData?.monthlyFee} TK
                  </span>
                </p>

                {userData.userType === "pppoe" && (
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#change_package_modal"
                    className="btn btn-sm btn-success change-btn"
                    disabled={changePackage.status === "pending"}
                  >
                    Change package
                  </button>
                )}
              </div>
            </div>

            {changePackage && (
              <div className="mt-3 current_package_container">
                <h5 style={{ color: "#3eff00" }}>Change Package :</h5>
                <div className="packages_info_wraper d-flex justify-content-between">
                  <p>
                    Date: {moment(changePackage.createdAt).format("YYYY-MM-DD")}
                  </p>
                  <p>
                    Package:{" "}
                    <span className="badge bg-secondary">
                      {packageChange(changePackage.mikrotikPackage)
                        ?.aliasName ||
                        packageChange(changePackage.mikrotikPackage)?.name}
                    </span>
                  </p>
                  <div>
                    <p>
                      Status:{" "}
                      {changePackage.status === "pending" ? (
                        <span className="badge bg-warning text-dark">
                          {changePackage.status}
                        </span>
                      ) : (
                        <span className="badge bg-success text-white">
                          {changePackage.status}
                        </span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="packageList">
            <h3
              className="text-uppercase mt-3 text-center bg-success p-2"
              style={{
                width: "fit-content",
                margin: "10px auto",
                borderRadius: "20px 0px 20px 0px",
              }}
            >
              Our packages
            </h3>
            <div className="row container m-auto">
              {userData.userType === "hotspot"
                ? hotspotPackage.map((item) => (
                    <div key={item.id} className="col-md-3 package_list_card">
                      <div
                        className="packageCard  text-white mb-3"
                        style={{
                          position: "static",
                          textAlign: "center",
                        }}
                      >
                        <div className="card-body mt-2">
                          {!userData?.reseller && (
                            <h4 className="card-title fw-bold">
                              {item.aliasName || item.name}
                            </h4>
                          )}
                          {userData?.reseller && (
                            <h4 className="card-title fw-bold">{item.name}</h4>
                          )}

                          <p
                            className="card-text mt-2"
                            style={{
                              backgroundColor: "#213b59",
                              borderRadius: "10px",
                              padding: "5px 8px",
                              width: "fit-content",
                              margin: "auto",
                            }}
                          >
                            {item.rate} TK /<span>Month</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                : packages.map((item) => (
                    <div key={item.id} className="col-md-3 package_list_card">
                      <div
                        className="packageCard  text-white mb-3"
                        style={{
                          position: "static",
                          textAlign: "center",
                        }}
                      >
                        <div className="card-body mt-2">
                          {!userData?.reseller && (
                            <h4 className="card-title fw-bold">
                              {item.aliasName || item.name}
                            </h4>
                          )}
                          {userData?.reseller && (
                            <h4 className="card-title fw-bold">{item.name}</h4>
                          )}

                          <p
                            className="card-text mt-2"
                            style={{
                              backgroundColor: "#213b59",
                              borderRadius: "10px",
                              padding: "5px 8px",
                              width: "fit-content",
                              margin: "auto",
                            }}
                          >
                            {item.rate} TK /<span>Month</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="text-center mt-3 data-not-found-container">
            <h1
              className="text-warning"
              style={{ paddingTop: "25vh" }}
            >
              Package not showing!
            </h1>
          </div>
        </>
      )}

      <PackageChangeModal />
    </div>
  );
}
