import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { billPaymentHistory } from "../features/getIspOwnerUsersApi";
import Loader from "../components/common/Loader";
import ViewPaymentHistoryModal from "./viewPaymentHistory";
import { BsEyeFill } from "react-icons/bs";
import PaymentModal from "./paymentModal";

const PaymentHistory = () => {
  const dispatch = useDispatch();
  const userData = useSelector(
    (state) => state.persistedReducer.auth?.currentUser.customer
  );
  const paymentHistories = useSelector((state) => state.client.paymentHistory);

  const [loading, setLoading] = useState(false);
  const [pHistory, setHistory] = useState({});

  useEffect(() => {
    billPaymentHistory(dispatch, setLoading);
  }, []);

  // get payment get way status
  const hasPG = userData.ispOwner.bpSettings.hasPG;

  return (
    <>
      <div className="paymentHistory">
        <div className="container">
          <p>Payment Details:</p>
          <div className="packages_info_wraper mw-75 ">
            <p>
              Next Payment Date:{" "}
              <span className="badge bg-secondary">
                {moment(userData?.billingCycle).format("DD-MMM-YYYY")}
              </span>
            </p>
            <p>
              Last Payment:{" "}
              <span className="badge bg-warning text-dark">
                {moment(paymentHistories[0]?.createdAt).format("DD-MMM-YYYY")}{" "}
              </span>{" "}
            </p>
            {hasPG && (
              <button
                data-bs-toggle="modal"
                data-bs-target="#billPaymentModal"
                type="button"
                className="btn btn-success "
              >
                Pay now
              </button>
            )}
          </div>
          <div className="payment_history_table">
            <div className="row">
              <div className="col-lg-12">
                <div className="wrapper wrapper-content animated fadeInRight">
                  <div className="ibox-content forum-container">
                    <div className="mt-3">
                      <p>
                        Total Payment History{" "}
                        <span className="badge bg-secondary fs-6">
                          {paymentHistories.length > 0
                            ? paymentHistories.length
                            : 0}
                        </span>
                      </p>

                      {loading ? (
                        <div className="text-center mt-5">
                          <Loader />
                        </div>
                      ) : (
                        paymentHistories.map((item) => (
                          <div
                            key={item.createdAt}
                            className="payment-table-data mt-3 border-0 align-items-center"
                          >
                            <div className="payment_history_data space space-2">
                              <strong>Pay Date:</strong>
                              <span
                                className="me-2 badge bg-secondary"
                                style={{ width: "fit-content" }}
                              >
                                {moment(item.createdAt).format("MMM-DD-YYYY")}
                              </span>
                            </div>
                            <div className="payment_history_data">
                              <strong>Package:</strong>
                              <span className="ms-1">{item.package}</span>
                            </div>
                            <div className="payment_history_data">
                              <strong>Month:</strong>
                              <span className="ms-1">{item.month}</span>
                            </div>
                            <div className="payment_history_data">
                              <strong>Amount:</strong>
                              <span className="ms-1">{item.amount} Tk.</span>
                            </div>
                            <div className="payment_history_data space">
                              <strong>Pay Method:</strong>
                              <span
                                className="ms-1"
                                style={{ width: "fit-content" }}
                              >
                                {item.medium}
                              </span>
                            </div>
                            <div className="payment_history_data">
                              <strong>Status:</strong>
                              <span
                                className={`ms-2 badge bg-${
                                  (item.paymentStatus === "paid" &&
                                    "success") ||
                                  (item.paymentStatus === "unpaid" && "danger")
                                }`}
                                style={{ width: "fit-content" }}
                              >
                                {item.paymentStatus}
                              </span>
                            </div>
                            <div
                              style={{ border: "none", textAlign: "center" }}
                            >
                              <button
                                onClick={() => setHistory(item)}
                                data-bs-target="#viewPaymentModal"
                                data-bs-toggle="modal"
                                style={{
                                  padding: "5px 15px",
                                  borderRadius: "5px",
                                  margin: "auto",
                                  backgroundColor: "#40f620a3",
                                  border: "none",
                                  color: "white",
                                }}
                              >
                                <BsEyeFill className="me-1 eyeFill" />
                                view
                              </button>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ViewPaymentHistoryModal payment={pHistory} />
      <PaymentModal />
    </>
  );
};

export default PaymentHistory;
