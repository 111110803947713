import { useState } from "react";
// external imports
import { ThemeProvider } from "styled-components";
import { themes, GlobalStyles } from "./themes";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PrivateOutlet from "./PrivateOutlet";
import Login from "./pages/auth/login/Login";
import NotFound from "./pages/NotFound/NotFound";
import Execute from "./pages/execute/Execute";
import Success from "./pages/success/Success";
import { useEffect } from "react";
import Cancel from "./pages/success copy/Success";
import Failed from "./pages/success copy 2/Success";
import ClientPage from "./ownerCustomer/index";
import { getUserApi } from "./features/actions/authAsyncAction";
import Header from "./components/admin/header/Header";

function App() {
  const [theme, setTheme] = useState("light");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.persistedReducer.auth?.currentUser);
  const navigate = useNavigate() 

  console.log('user', user)

  useEffect(() => {
    const userId = user?.user?.id;

    if (userId) {
      getUserApi(dispatch, userId);
    }
  }, []);

  useEffect(() => { 
    if(!!user){
      navigate("isp/customer")
    }
   },[user])
  return (
    <ThemeProvider theme={themes[theme]}>
      <GlobalStyles />
      <div className="App">
        {/* {!user ? <Header /> : <Navigate to={"isp/customer"} />} */}

         
        {/* {!user && <Header />} */}

        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route
            path="/login"
            element={!user ? <Login /> : <Navigate to={"isp/customer"} />}
          />

          <Route path="/payment/execute" element={<Execute />} />
          <Route path="/payment/success" element={<Success />} />
          <Route path="/payment/cancel" element={<Cancel />} />
          <Route path="/payment/failed" element={<Failed />} />

          {/* client page  */}
          <Route path="/*" element={<PrivateOutlet />}>
            <Route path="isp/customer" element={<ClientPage />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
