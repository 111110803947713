import React from "react";
import { ErrorMessage, useField } from "formik";

export const LoginField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="mb-3">
      <p
        className="form-label"
      >
        {label}
      </p>
      <input
        className={`form-control form-input ${
          meta.touched && meta.error && "is-invalid"
        }`}
        {...field}
        {...props}
        autoComplete="off"
        placeholder={props.placeholder}
      />
      <ErrorMessage
        component="div"
        name={field.name}
        className="errorMessage"
      />
    </div>
  );
};
