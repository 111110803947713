import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PaymentModal from "./paymentModal";
import bkashImg from "../assets/img/bkash.jpg";
import {
  getPackagesByIspOwer,
  hotspotCustomerPackage,
} from "../features/getIspOwnerUsersApi";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Download, Upload } from "react-bootstrap-icons";

export default function ClientProfile() {
  const dispatch = useDispatch();

  // get own data
  const userData = useSelector(
    (state) => state.persistedReducer.auth?.currentUser.customer
  );

  const bpSettings = useSelector(
    (state) => state.persistedReducer.auth?.userData?.bpSettings
  );

  // get all packages
  const ppPackage = useSelector((state) =>
    bpSettings?.hasMikrotik
      ? state?.mikrotik?.packagefromDatabase
      : state?.package?.packages
  );

  const hotspotpPackage = useSelector(
    (state) => userData.userType === "hotspot" && state?.package?.hotspotPackage
  );

  // ispOwner permission
  const permission = userData?.ispOwner.bpSettings;

  // get payment get way status
  const hasPG = userData.ispOwner.bpSettings.hasPG;

  // modal handler state
  const [show, setShow] = useState(false);

  // find alias name method
  const findAliasName = (ownPackage) => {
    const findItem = ppPackage.find((item) => item.name.includes(ownPackage));
    return findItem;
  };

  const staticMikrotikPackage = (value) => {
    const temp = ppPackage.find((val) => val.id === value);
    return temp;
  };

  const hotspotPackageFind = (value) => {
    const temp = hotspotpPackage.find((val) => val.id === value);
    return temp;
  };

  // get package api call
  useEffect(() => {
    if (userData.userType === "hotspot") {
      hotspotCustomerPackage(dispatch, userData?.ispOwner.id);
    } else {
      getPackagesByIspOwer(dispatch);
    }
  }, []);

  const diffFormBillDate = moment(userData.billingCycle).diff(
    new Date(),
    "days"
  );

  return (
    <>
      <div className="customer-information-container">
        <div className="container">
          {/* <h4 className="pt-2 text-center">Customer Information</h4> */}
          <div className="d-flex">
            <h3 className="welcome_title my-2">
              Welcome to {userData.ispOwner.company}
            </h3>
          </div>
          {diffFormBillDate <= 7 && userData.paymentStatus !== "paid" && (
            <div className="mb-4 text-center">
              <div className="h5 p-1">
                <strong>Warning: </strong> Your billing date expire in{" "}
                <span className="text-warning">
                  {moment(userData.billingCycle).format("DD MMM YYYY")}
                </span>
              </div>
              {hasPG && (
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#billPaymentModal"
                  type="button"
                  className="btn btn-success "
                >
                  Pay now
                </button>
              )}
            </div>
          )}
          <div className="d-flex justify-content-around flex-wrap information-container">
            <div className=" px-1 mb-2 text-center customer_profile_details">
              <h3>ID: {userData?.customerId}</h3>
              <h4 className="mt-0">{userData?.name}</h4>
              <h6>
                {userData.userType === "pppoe"
                  ? userData?.pppoe?.name
                  : userData?.queue?.name}
              </h6>

              <div className="d-flex flex-column gap-2 mt-3">
                <div>
                  Balance: <span>{userData?.balance.toFixed(2) || 0} Tk.</span>
                </div>
                <div>
                  Monthly fee: <span>{userData?.monthlyFee || 0} Tk.</span>
                </div>
                <div>
                  Status:{" "}
                  <span
                    className={`badge fs-6 bg-${
                      userData?.status
                        ? (userData?.status === "active" && "success") ||
                          (userData?.status === "expired" && "danger")
                        : " warning"
                    }`}
                  >
                    {userData?.status}
                  </span>
                </div>
              </div>
            </div>

            <div className="px-1 mb-2 customer_details">
              <h3 className="text-center border-bottom border-2">
                Profile Details
              </h3>
              <div className="m-auto" style={{ width: "fit-content" }}>
                <p>
                  User Name:
                  <span>
                    {userData.userType === "pppoe"
                      ? userData?.pppoe?.name
                      : userData?.queue?.name}
                  </span>
                </p>
                {permission?.showCustomerPanelPackage && (
                  <div>
                    Package:
                    {userData.userType === "pppoe" && (
                      <span className="badge text-black fs-6 bg-warning">
                        {!userData?.reseller &&
                          (findAliasName(userData?.pppoe.profile)?.aliasName ||
                            findAliasName(userData?.pppoe.profile)?.name)}
                        {userData?.reseller &&
                          findAliasName(userData?.pppoe.profile)?.name}
                      </span>
                    )}
                    {userData.userType === "simple-queue" && (
                      <span className="badge text-black fs-6 bg-warning">
                        {parseInt(
                          userData.queue.maxLimit.split("/")[1] / 1000000
                        )}
                        MBps
                      </span>
                    )}
                    {userData.userType === "firewall-queue" && (
                      <span className="badge text-black fs-6 bg-warning">
                        {staticMikrotikPackage(userData?.mikrotikPackage)?.name}
                      </span>
                    )}
                    {userData.userType === "hotspot" && (
                      <span className="badge text-black fs-6 bg-warning">
                        {hotspotPackageFind(userData?.hotspotPackage)?.name}
                      </span>
                    )}
                  </div>
                )}
                <p>
                  Service:
                  <span>
                    {userData.userType === "pppoe" ? "PPPoE" : "Static"}
                  </span>
                </p>
                <p>
                  Create:
                  <span>
                    {moment(userData?.createdAt).format("MMM DD YYYY")}
                  </span>
                </p>
                <p>
                  Billing Cycle:
                  <span>
                    {moment(userData?.billingCycle).format("MMM DD YYYY")}
                  </span>
                </p>
                <p>
                  PromiseDate:
                  <span>
                    {moment(userData?.promiseDate).format("MMM DD YYYY")}
                  </span>
                </p>
              </div>
            </div>

            <div className="px-1 personal_information">
              <h3 className="text-center border-bottom border-2">
                Personal Information
              </h3>
              <div className="m-auto" style={{ width: "fit-content" }}>
                <p>
                  Name: <span>{userData?.name}</span>
                </p>
                <p>
                  Mobile: <span>{userData?.mobile}</span>
                </p>
                <p>
                  Email:
                  <span>{userData?.email || "N/A"}</span>
                </p>
                {userData.userType === "pppoe" ? (
                  <p>
                    Password:
                    <span>{userData?.pppoe?.password}</span>
                  </p>
                ) : (
                  <p>
                    Target:
                    <span>{userData?.queue?.target}</span>
                  </p>
                )}
                <p>
                  Address:
                  <span>{userData?.address || "N/A"}</span>
                </p>
              </div>
            </div>
          </div>

          <div className="up_downLoad">
            {permission?.showCustomerPanelPackage ? (
              <>
                <div className="up_down upload">
                  <div className="upload-icon-container">
                    <Upload className="upload-icon" />
                  </div>
                  <div>
                    <p className="text-white fw-bold">Upload</p>
                    {userData.userType === "simple-queue" && (
                      <h3>
                        {parseInt(
                          userData.queue.maxLimit.split("/")[0] / 1000000
                        )}
                        MBps
                      </h3>
                    )}
                    {userData.userType === "pppoe" && (
                      <h3 style={{ wordWrap: "break-word" }}>
                        {!userData?.reseller &&
                          (findAliasName(userData?.pppoe.profile)?.aliasName ||
                            findAliasName(userData?.pppoe.profile)?.name)}
                        {userData?.reseller &&
                          findAliasName(userData?.pppoe.profile)?.name}
                      </h3>
                    )}

                    {userData.userType === "hotspot" && (
                      <h3>
                        {hotspotPackageFind(userData?.hotspotPackage)?.name}
                        {/* {parseInt(userData.queue.maxLimit.split("/")[0] / 1000000)} */}
                      </h3>
                    )}
                  </div>
                </div>
                <div className="up_down download">
                  <div className="download-icon-container">
                    <Download className="download-icon" />
                  </div>
                  <div>
                    <p className="text-white fw-bold">Downlaod</p>
                    {userData.userType === "pppoe" && (
                      <h3>
                        {!userData?.reseller &&
                          (findAliasName(userData?.pppoe.profile)?.aliasName ||
                            findAliasName(userData?.pppoe.profile)?.name)}
                        {userData?.reseller &&
                          findAliasName(userData?.pppoe.profile)?.name}
                      </h3>
                    )}
                    {userData.userType === "simple-queue" && (
                      <h3>
                        {parseInt(
                          userData.queue.maxLimit.split("/")[1] / 1000000
                        )}
                        MBps
                      </h3>
                    )}
                    {userData.userType === "hotspot" && (
                      <h3>
                        {hotspotPackageFind(userData?.hotspotPackage)?.name}
                        {/* {parseInt(userData.queue.maxLimit.split("/")[0] / 1000000)} */}
                      </h3>
                    )}
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {hasPG && (
              <div
                className="up_down payment"
                data-bs-toggle="modal"
                data-bs-target="#billPaymentModal"
                style={{ cursor: "pointer" }}
              >
                <div className="text-center">
                  <img className="w-50" src={bkashImg} alt="" />
                  <h3>Payment</h3>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* payment modal */}
      <PaymentModal />
    </>
  );
}
