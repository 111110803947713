import React, { useEffect, useState } from "react";
import { ispOwnerSupportNumbers } from "../features/getIspOwnerUsersApi";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/common/Loader";

const OwnerSupportNumbers = () => {
  const dispatch = useDispatch();

  const ispOwner = useSelector(
    (state) => state.persistedReducer.auth?.currentUser?.customer?.ispOwner
  );

  //Loading state
  const [isLoading, setIsLoading] = useState(false);

  // get support numbers state
  const [support, setSupport] = useState(null);

  // get api ispOwner customer support
  useEffect(() => {
    ispOwnerSupportNumbers(dispatch, ispOwner.id, setIsLoading, setSupport);
  }, [ispOwner]);

  return (
    <div className="supportNumberContainer" style={{ minHeight: "55vh" }}>
      {isLoading ? (
        <div className="text-center mt-5">
          <Loader />
        </div>
      ) : (
        <>
          {support && support.length > 0 ? (
            <>
              <h3 style={{ fontFamily: "italic" }} className="text-center">
                Support Contact Number
                <hr />
              </h3>
              <table class="table table-striped table-borderless table-responsive-sm container support-number-table">
                <thead>
                  <tr style={{ backgroundColor: "#11a23abc" }}>
                    <th
                      scope="col"
                      className="text-center align-content-center name-space"
                    >
                      Supporter Name
                    </th>
                    <th
                      scope="col"
                      className="text-center align-content-center"
                    >
                      Supporter Mobile
                    </th>
                    <th
                      scope="col"
                      className="text-center align-content-center"
                    >
                      Start Time
                    </th>
                    <th
                      scope="col"
                      className="text-center align-content-center"
                    >
                      End Time
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {support?.map((val) => (
                    <tr
                      key={val.id}
                      className="forum-item"
                      style={{ fontWeight: "bold" }}
                    >
                      <td
                        scope="row"
                        className="text-center align-content-center"
                        style={{ wordBreak: "break-word" }}
                      >
                        {val?.name || "N/A"}
                      </td>
                      <td className="text-center align-content-center">
                        {val?.mobile}
                      </td>
                      <td className="text-center align-content-center">
                        {val?.start}
                      </td>
                      <td className="text-center align-content-center">
                        {val?.end}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <>
              <div className="text-center mt-3">
                <h1 className="text-warning" style={{ paddingTop: "23vh" }}>
                  Support number not found!
                </h1>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default OwnerSupportNumbers;
