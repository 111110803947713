import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import {
  BarChart,
  CurrencyDollar,
  PersonFill,
  GearFill,
  KeyFill,
  BoxArrowLeft,
} from "react-bootstrap-icons";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { userLogout } from "../features/actions/authAsyncAction";
import "./client.css";
import ClientProfile from "./ClientProfile";
import Packages from "./packages";
import PasswordReset from "./PasswordReset";
import Payment from "./Payment";
import PaymentHistory from "./PaymentHistory";
import SupportTicket from "./SupportTicket";
import OwnerSupportNumbers from "./OwnerSupportNumbers";
import { RiGpsFill, RiHomeWifiLine } from "react-icons/ri";
import { MdCorporateFare } from "react-icons/md";
import { LuWifi } from "react-icons/lu";
import { HiMiniServerStack } from "react-icons/hi2";
import { TfiAlignJustify, TfiEmail } from "react-icons/tfi";
import { BiMessageRoundedError, BiPhoneCall } from "react-icons/bi";
import { BsCashCoin } from "react-icons/bs";

const Client = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const [renderText, setRenderText] = useState("profile");
  const dispatch = useDispatch();

  // logout
  const handleLogOut = async () => {
    userLogout(dispatch);
  };

  const userData = useSelector(
    (state) => state.persistedReducer.auth?.currentUser.customer
  );

  const hasPG = userData?.ispOwner?.bpSettings?.hasPG;

  useEffect(() => {
    const rText = localStorage.getItem("nf:textR"); //render page from local storage
    if (rText) setRenderText(rText);
  }, []);

  const renderPageController = (text) => {
    localStorage.setItem("nf:textR", text); //set page in local storage
    setRenderText(text);
    setIsExpand(false);
  };

  const serviceData = [
    {
      header: "Home Internet",
      desc: "Get ready for more potential and more opportunity",
      icon: <RiHomeWifiLine />,
    },
    {
      header: "Corporate Connectivity",
      desc: "More than 1K corporate clients have trust in us",
      icon: <MdCorporateFare />,
    },
    {
      header: "SME Internet",
      desc: "Designed for more speed within a reasonable price",
      icon: <LuWifi />,
    },
    {
      header: "Dedicated Server",
      desc: "Powerful Dedicated SSD Hosting with Root Access",
      icon: <HiMiniServerStack />,
    },
    {
      header: "GPS Tracker",
      desc: "Suitable for personal use or any type of business",
      icon: <RiGpsFill />,
    },

    {
      header: "Business Email",
      desc: "The most well-known which is said to have originated",
      icon: <TfiEmail />,
    },
  ];

  //Fleck:
  (async function () {
    if (CSS["paintWorklet"] === undefined) {
      await import("https://unpkg.com/css-paint-polyfill");
    }

    CSS.paintWorklet.addModule(
      "https://unpkg.com/@georgedoescode/houdini-fleck"
    );

    // Fix a weird Safari/Firefox polyfill issue...
    setTimeout(() => {
      document.querySelectorAll(".fleck-demo").forEach((el) => {
        el.style.width = "100%";
      });
    }, 250);
  })();

  return (
    <section className="client_section fleck-demo center-xy" data-theme="green">
      <ToastContainer />
      <div className="client_wraper">
        <div
          className={`settings_wraper ${
            isMenuOpen ? "show-menu" : "hide-menu"
          }`}
        >
          <ul className="client_service_list ps-0">
            <li
              className={renderText === "profile" ? "pageActive" : ""}
              onClick={() => renderPageController("profile")}
            >
              <div className="menu_icon">
                <PersonFill />
              </div>
              <div className="menu_label">Profile</div>
            </li>
            <hr className="mt-0 mb-0" />

            <li
              className={renderText === "packages" ? "pageActive" : ""}
              onClick={() => renderPageController("packages")}
            >
              <div className="menu_icon">
                <BarChart />
              </div>
              <div className="menu_label">Packages</div>
            </li>
            <hr className="mt-0 mb-0" />
            <li
              className={renderText === "supportNumbers" ? "pageActive" : ""}
              onClick={() => renderPageController("supportNumbers")}
            >
              <div className="menu_icon">
                <BiPhoneCall />
              </div>
              <div className="menu_label">Support Numbers</div>
            </li>
            <hr className="mt-0 mb-0" />
            <li
              className={renderText === "supportPage" ? "pageActive" : ""}
              onClick={() => renderPageController("supportPage")}
            >
              <div className="menu_icon">
                <BiMessageRoundedError />
              </div>
              <div className="menu_label">Support Ticket</div>
            </li>
            <hr className="mt-0 mb-0" />

            {hasPG && (
              <>
                <li onClick={() => renderPageController("payBill")}>
                  <div className="menu_icon">
                    <BsCashCoin />
                  </div>
                  <div className="menu_label">Pay bill</div>
                </li>
                <hr className="mt-0 mb-0" />
              </>
            )}

            <li
              className={renderText === "paymentHistory" ? "pageActive" : ""}
              onClick={() => renderPageController("paymentHistory")}
            >
              <div className="menu_icon">
                <CurrencyDollar />
              </div>
              <div className="menu_label">Payment History</div>
            </li>
            <hr className="mt-0 mb-0" />

            <li
              className={renderText === "resetPassword" ? "pageActive" : ""}
              onClick={() => renderPageController("resetPassword")}
            >
              <div className="menu_icon">
                <KeyFill />
              </div>
              <div className="menu_label">Reset Password</div>
            </li>
            <hr className="mt-0 mb-0" />

            <li onClick={handleLogOut}>
              <div className="menu_icon">
                <BoxArrowLeft />
              </div>
              <div className="menu_label">Logout</div>
            </li>
          </ul>
          <div className="setting_icon_wraper">
            <div
              onClick={() => {
                setMenuOpen(!isMenuOpen);
                setIsExpand(false);
              }}
              className="client_setting_icon"
            >
              <GearFill />
            </div>
          </div>
        </div>
      </div>
      <div className="client_profile">
        <Navbar
          className="client_navbar"
          collapseOnSelect
          expand="lg"
          expanded={isExpand}
          variant="light"
          style={{ zIndex: "111" }}
        >
          <Container>
            <Navbar.Brand href="/">
              <span className="navHeaderCompany">
                {userData.ispOwner.company}
              </span>
            </Navbar.Brand>
            <Navbar.Toggle
              className="shadow-none border-0"
              aria-controls="responsive-navbar-nav"
              onClick={() => {
                setMenuOpen(false);
                setIsExpand(!isExpand);
              }}
            >
              <TfiAlignJustify className="text-white" />
            </Navbar.Toggle>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav
                className="ms-auto client_nav_link fw-bold align-content-center text-center "
                style={{ transition: "all 0.3s ease-in-out" }}
              >
                <Nav.Link
                  className={renderText === "profile" ? "pageActive2" : ""}
                  onClick={() => renderPageController("profile")}
                >
                  Profile
                </Nav.Link>
                <Nav.Link
                  className={renderText === "packages" ? "pageActive2" : ""}
                  onClick={() => renderPageController("packages")}
                >
                  Package
                </Nav.Link>
                <Nav.Link
                  className={
                    renderText === "supportNumbers" ? "pageActive2" : ""
                  }
                  onClick={() => renderPageController("supportNumbers")}
                >
                  Support Numbers
                </Nav.Link>
                <Nav.Link
                  className={renderText === "supportPage" ? "pageActive2" : ""}
                  onClick={() => renderPageController("supportPage")}
                >
                  Support Ticket
                </Nav.Link>

                {hasPG && (
                  <Nav.Link
                    className={renderText === "payBill" ? "pageActive2" : ""}
                    onClick={() => renderPageController("payBill")}
                  >
                    Pay Bill
                  </Nav.Link>
                )}

                <Nav.Link
                  className={
                    renderText === "paymentHistory" ? "pageActive2" : ""
                  }
                  onClick={() => renderPageController("paymentHistory")}
                >
                  Payment History
                </Nav.Link>
                <Nav.Link
                  className={
                    renderText === "resetPassword" ? "pageActive2" : ""
                  }
                  onClick={() => renderPageController("resetPassword")}
                >
                  Reset Password
                </Nav.Link>
                <Nav.Link onClick={handleLogOut}>Logout</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <section className="client_dashboard_section">
          <div className="customer_information">
            {renderText === "profile" && <ClientProfile />}
            {renderText === "resetPassword" && <PasswordReset />}
            {renderText === "packages" && <Packages />}
            {renderText === "payBill" && <Payment />}
            {renderText === "paymentHistory" && <PaymentHistory />}
            {renderText === "supportPage" && <SupportTicket />}
            {renderText === "supportNumbers" && <OwnerSupportNumbers />}
          </div>
        </section>
        {renderText === "profile" && (
          <div className="container services">
            <div className="section-header">
              <h2
                className="text-uppercase mt-1 text-center bg-success p-2"
                style={{
                  width: "fit-content",
                  margin: "auto",
                  borderRadius: "20px 0px 20px 0px",
                }}
              >
                Our Services
              </h2>
            </div>
            <section className="section" id="courses">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-12">
                    <div className="features-absolute">
                      <div className="row mb-2">
                        {serviceData.map((item) => (
                          <div
                            key={item}
                            className="col-lg-3 col-md-6 col-12 mb-3"
                          >
                            <div className="features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md text-center service-card">
                              <div className="icons text-primary text-center mx-auto">
                                <i className="d-block rounded h1 fw-bold mb-0 text-white-50 ">
                                  {item?.icon}
                                </i>
                              </div>

                              <div className="card-body p-0 content ">
                                <h5 className="mt-4">
                                  <span className="title text-white">
                                    {item.header}
                                  </span>
                                </h5>
                                <p className="text-white-50">{item.desc}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}

        <footer className="footer text-start">
          <div className="client_footer p-4 align-items-center container">
            <div style={{ flex: 6, textAlign: "justify" }}>
              To get started with us, please go over all of our internet plans
              and other plans to let our operators know which one works for you
              the best! We guarantee high-quality customer support service with
              high-speed broadband connection all over the country.
            </div>

            <div style={{ flex: 6, textAlign: "center" }}>
              <h3>Contact</h3>
              <hr />
              <p className="fw-bold">{userData.ispOwner?.company || ""}</p>
              <p>Mobile: {userData.ispOwner.mobile}</p>
              <p>Office: {userData.ispOwner.address}</p>
              <p>Email: {userData.ispOwner.email}</p>
            </div>
          </div>
        </footer>
      </div>
    </section>
  );
};

export default Client;

//customerId-> 6293c72e50bdd42730321733
