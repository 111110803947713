import moment from "moment";
import React from "react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/common/Loader";
import {
  createSupportTicketApi,
  getAllSupportTicketApi,
} from "../features/getIspOwnerUsersApi";

const SupportTicket = () => {
  const supportForm = useRef();
  const dispatch = useDispatch();
  const userData = useSelector(
    (state) => state.persistedReducer.auth?.currentUser.customer
  );

  const supportTicket = useSelector((state) => state.client.supportTicket);

  const [supportMessage, setSupportMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = ({ target }) => {
    if (target.value) {
      setSupportMessage(target.value);
      return setError("");
    } else {
      setSupportMessage("");
      setError("Please write your support message");
    }
  };

  const createSupportTicket = () => {
    if (!supportMessage) {
      supportForm.current.focus();
      return setError("Please write your support message");
    }
    const data = {
      message: supportMessage,
      ispOwner: userData.ispOwner.id,
      customer: userData.id,
    };
    if (userData.reseller) {
      data.reseller = userData.reseller;
    }
    createSupportTicketApi(data, dispatch, setLoading, setSupportMessage);
  };

  useEffect(() => {
    getAllSupportTicketApi(dispatch, setLoading);
  }, []);

  return (
    <div className="support-ticket-section" style={{ minHeight: "58vh" }}>
      <div className="container">
        <h4>Ticket Details:</h4>
        <div className="packages_info_wraper mw-75 ">
          <p>
            Total Support Receive:{" "}
            <span className="badge bg-secondary fs-6 fw-bold">
              {supportTicket ? supportTicket.length : 0}
            </span>
          </p>
          <p>
            Total Pending:{" "}
            <span className="badge bg-danger text-white fs-6">
              {SupportTicket
                ? supportTicket.filter((item) => item.status === "pending")
                    .length
                : 0}
            </span>{" "}
          </p>
        </div>
        <h4 className="mt-3">Get Support Ticket </h4>
        <div className="support-message-form">
          <input
            className="form-control"
            ref={supportForm}
            name="message"
            type="text"
            onChange={handleChange}
            value={supportMessage}
            placeholder="Type Your Problem"
          />

          <button
            onClick={createSupportTicket}
            className="btn btn-sm btn-success ms-2 shadow-none"
          >
            {loading ? <Loader /> : "Get Support"}
          </button>
        </div>
        {error && !supportMessage && <p className="text-warning">{error}</p>}
        <div className="support-ticket-table border-0">
          {loading ? (
            <div className="text-center mt-5">
              <Loader />
            </div>
          ) : (
            supportTicket.map((item, index) => (
              <div
                key={item.createdAt}
                className="support-table-data mt-3 border-0"
              >
                <div className="support_ticket_data space">
                  <strong>Ticket ID:</strong>
                  <p>{item?.ticketId}</p>
                </div>

                <div className="support_ticket_data ticket_details">
                  <strong>Message:</strong>
                  <p>{item.message}</p>
                </div>

                <div className="support_ticket_data">
                  <strong>Status:</strong>
                  <span
                    className={`ms-1 badge bg-${
                      (item.status === "pending" && "danger") ||
                      (item.status === "processing" && "info") ||
                      (item.status === "completed" && "success")
                    }`}
                    style={{ width: "fit-content" }}
                  >
                    {item.status}
                  </span>
                </div>

                <div className="support_ticket_data">
                  <strong>Applied:</strong>
                  <span
                    className="ms-2 badge bg-secondary"
                    style={{ width: "fit-content" }}
                  >
                    {moment(item.createdAt).format("MMM-DD-YYYY")}
                  </span>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default SupportTicket;
